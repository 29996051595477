<template>
  <BoxModal :configModal="configModal">
    <div class="modal-trocar-revenda">
      <div>
        <div class="trocar-revenda-topo">
          <p>Bem-Vindo ao</p>
          <img src="@/assets/img/logos/topnews-v-n.svg" alt="+TOP Dash" />
          <p>Escolha sua Revenda</p>
        </div>
        <SelectRevenda />
      </div>
    </div>
    <img class="bg-news-1" src="@/assets/img/icone/icone-news-top.png" alt="+TOP Dash" />
    <img class="bg-news-2" src="@/assets/img/icone/icone-news-bottom.png" alt="+TOP Dash" />
  </BoxModal>
</template>

<script>
const BoxModal = () => import('@/components/modal/_ModalBox.vue');
const SelectRevenda = () => import('@/components/elementos/SelectRevenda.vue');
export default {
  name: 'ModalTrocarRevenda',
  components: {
    BoxModal,
    SelectRevenda,
  },
  data() {
    return {
      configModal: {
        btnFechar: true,
        btnFecharClaro: true,
        modalRevenda: true,
      },
    };
  },
};
</script>

<style lang="scss">
.modal-trocar-revenda {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100vw - 50px);
  max-width: 940px;
  height: 70vh;
  max-height: 515px;
  padding: 30px 20px;
  background: #220147;
  min-height: 500px;
  @media (max-width: 800px) {
    min-height: 0px;
    width: 100vw;
    height: 100%;
    max-height: 100%;
  }
  > div {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  .trocar-revenda-topo {
    box-sizing: border-box;
    width: 100%;
    max-width: 293px;
    padding: 0 20px;
    margin: 0 auto;
    p {
      color: $texto-claro;
      &:first-of-type {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 7px;
      }
      &:last-of-type {
        font-size: 22px;
        font-weight: 900;
        margin: 20px 0 10px 0;
      }
    }
    img {
      max-width: 100%;
    }
  }
}
.bg-news-1,
.bg-news-2 {
  position: absolute;
  width: 60%;
  @media (max-width: 800px) {
    width: 100%;
  }
}
.bg-news-1 {
  right: 0;
  top: 0;
}
.bg-news-2 {
  left: 0;
  bottom: 0;
}
</style>
